<template>
  <v-text-field
    ref="document"
    v-mask="mask.cpf"
    :value="value"
    :label="label"
    data-cy="userCpf"
    :loading="loading"
    :rules="cpfRules"
    filled
    v-on="on"
    @blur="blurField" />
</template>

<script>
  import { states } from '@/utils'
  // import formsMixin from '@/mixins/forms'
  import masks from '@/mixins/masks'
  import validate from '@/mixins/validate'
  import { mapGetters } from 'vuex'

  export default {
    // mixins: [ formsMixin ],
    mixins: [ masks, validate ],
    props: {
      label: {
        type: String,
        required: true
      },
      hasInvestigator: {
        type: Boolean,
        default: true
      },
      value: {
        type: String
      },
      on: {
        type: Object
      }
    },
    data: () => ({
      modalSmartData: false,
      acceptSmartData: false,
      showAddPhones: false,
      isActiveSmartData: false,
      changePhone: false,
      states: states,
      emails: [],
      phones: [],
      newPhones: [],
      isEmptyData: null
    }),
    computed: {
      ...mapGetters({
        companyId: 'currentCompany/id',
        loading: 'investigator/loading',
        isLoading: 'app/getLoading'
      })
    },
    watch: {
      // items (val) {
      //   this.dataItems = val

      //   const companiesAbilities = val.companiesAbilities || {}
      //   const companyAbility = companiesAbilities[this.companyId] || {}
      //   this.dataItems.caseCreate = companyAbility.caseCreate
      //   this.dataItems.caseNegotiate = companyAbility.caseNegotiate

      //   this.showAddPhones = this.requiredPhone
      // },
      'value' (cpf) {
        this.hasInvestigator && cpf !== null && cpf.length < 11 ? this.clearFields() : this.setNotData()
        if (this.hasInvestigator && this.cpfRules[1](cpf) === true) {
          this.getInvestigator(cpf)
        }
      },
      isEmptyData (isEmpty) {
        this.$emit('empty', isEmpty)
      },
      requiredPhone (value) {
        this.showAddPhones = this.changePhone = value
      }
    },
    mounted () {
      this.dataItems = {} //Object.assign({}, this.items)
      // this.isDisabled = this.editAction
      this.showAddPhones = this.requiredPhone
    },
    methods: {
      removePhone (index) {
        this.newPhones.splice(index, 1)
        this.dataItems.phones.splice(index, 1)
      },
      addPhone () {
        let phone = {
          areaCode: '',
          countryCode: '',
          number: '',
          type: ''
        }
        // a condicao abaixo só é verdadeira se tiver investigator funcinando
        // let options = this.phones.filter(phone => !this.dataItems.phones.includes(phone))
        // this.newPhones.push({ options })
        this.newPhones.push(phone)
      },
      showAddAction (index) {
        // return this.changePhone && this.newPhones.length === parseInt(index) + 1
        return this.newPhones.length === parseInt(index) + 1
      },
      showRemoveAction (index) {
        if (this.requiredPhone && this.newPhones.length === 1) {
          return false
        } else {
          return this.newPhones.length === index + 1
        }
      },
      saveDate (date) {
        var setDate = {birthDate: date}
        this.dataItems = Object.assign(this.dataItems, setDate)
      },
      blurField () {
        this.$emit('blur', this.dataItems)
        this.isDisplayMode = false
        // this.newPhones = Object.assign([], this.items.phones)
      },
      // setDisabledFields () {
      //   if (this.$route.name === 'CompanyNew' || this.$route.name === 'UserNew' || this.$route.name === 'CaseNew') {
      //     this.isDisabled = true
      //   }
      // },
      changeEmail (email) {
        if (!email || !email[0]) {
          return
        }
        this.dataItems.email = email[0].email ? email[0].email : email[0]
      },
      changeNewPhone (selectedPhone, index) {
        const isArray = selectedPhone.constructor === Array
        selectedPhone = isArray ? selectedPhone[0] : selectedPhone
        const isErase = selectedPhone.type === ''

        if (isErase) {
          this.dataItems.phones.splice(index, 1)
        } else {
          this.changePhone = true
          this.dataItems.phones.push(selectedPhone)
        }

        this.$emit('blur', this.dataItems)
      },
      clearFields () {
        this.acceptSmartData = false
        this.dataItems.name = ''
        this.dataItems.birthDate = ''
        this.dataItems.email = ''
        this.emails = []
        // this.newPhones = []
        this.isEmptyData = true
      },
      setNotData () {
        this.isEmptyData = false
      },
      setSmartData () {
        this.$nextTick(() => { this.modalSmartData = true })
      },
      closeModalSmart () {
        this.modalSmartData = false
        this.isActiveSmartData = this.acceptSmartData
      },
      saveModalSmart () {
        this.acceptSmartData = true
        this.closeModalSmart()
        this.$nextTick(() => { this._focusDocumentField() })
      },
      _focusDocumentField () {
        if (this.dataItems.document === '') {
          this.$refs.document.focus()
        }
      },
      isMobile(value) {
        const numbers = value.replace('+55', '').replace(/\D+/g, '').replace(/0/, '')
        const digit = parseInt(numbers[2] || 0)
        const digits = [6, 7, 8, 9]
        return digits.includes(digit)
      },
      getInvestigator (cpf) {
        this
          .$store
          .dispatch('investigator/search', cpf)
          .then(investigator => {
            // this.isDisabled = false
            if (investigator && investigator.name) {
              this.dataItems.name = investigator.name
              if(investigator.birthDate){
                const birthDate = investigator.birthDate
                const formattedDate = new Date(birthDate).toISOString().substring(0, 10).replace(/-/g, '/').split('/').reverse().join('/')
                this.dataItems.birthDate = formattedDate
              }
              this.emails = investigator.emails

              const phones = investigator.phones.map(item => {
                const regex = this.isMobile(item.number) ? /(\+\d{2})(\d{2})(\d{5})(\d+)/ : /(\+\d{2})(\d{2})(\d{4})(\d+)/
                return {...item, number: item.number.replace(regex, '$1 $2 $3-$4') }
              })

              this.dataItems = {
                ...investigator,
                phones
              }


              this.$emit('investigator', this.dataItems)
            } else {
              this.$emit('investigator', {})
              this.clearFields()
            }
          })
      }
    }
  }
</script>
